.anti-block {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.card {
  background: rgba(238, 238, 238, 0.5);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.25);
  padding: 20px;
}

.card .user img {
  width: 50px;
  height: auto;
}

.card .user div {
  padding: 0 100px;
  background: #d7d7d7;
  border-radius: 20px;
  font-size: 10px;
}

.card .dialogs {
  max-height: 450px;
  overflow-y: auto;
}

.card .dialog {
  padding: 0 15px;
}

.card .dialog .row .col-9 {
  padding: 15px;
  color: #fff;
  margin: 5px 0;
}

.card .sender .row .col-9 {
  background: #3364ff;
  border-radius: 20px 20px 20px 0;
}

.card .receiver .row .col-9 {
  background: #c5d2fd;
  color: #000;
  border-radius: 20px 20px 0 20px;
}

.card input {
  padding: 20px;
  width: 100% !important;
  padding-right: 15px !important;
  font-size: 15px;
  border-radius: 10px;
  background-color: #fff;
  border: none;
  margin-top: 20px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1) !important;
}

.respont-widget {
  position: absolute;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1) !important;
  width: 50px;
  z-index: 1;
  right: 0;
  margin-top: -25px;
  margin-right: 0;
}

.respont-widget img {
  width: 50px;
  height: auto;
  border-radius: 50%;
}
