.footer {
  padding: 30px 0 30px 0;
  background-color: transparent;
  color: #000;
}

.footer .contact{
	text-align: right;
}

.footer .contact a {
  color: #000;
  display: block;
  margin: 0 5px;
  font-size: 25px;
  font-weight: bold;
	display: inline-block;
}

.footer .contact a i {
  margin-right: 5px;
	opacity: 0.4;
}

.footer .subscribe p {
  font-size: 20px;
  margin-top: 20px;
  font-weight: bold;
  color: #fff;
	text-decoration: underline;
	text-decoration-color: #D9B959;
	text-decoration-thickness: 3px;
	text-underline-offset: 5px;
}

.footer .subscribe div {
  display: flex;
  flex-wrap: nowrap;
}

.footer .subscribe div input {
  padding: 10px;
  width: 100%;
  border-radius: 10px 0 0 10px;
  height: 50px;
  border: none;
  background-color: rgba(255, 255, 255, 0.3);
  color: #fff;
}

.footer .subscribe div input::placeholder {
  color: #fff;
}

.footer .subscribe div input:-ms-input-placeholder {
  color: #fff;
}

.footer .subscribe div input::-ms-input-placeholder {
  color: #fff;
}

.footer .subscribe div button {
  font-weight: bold;
  background-color: #fdcc08;
  color: #3364ff;
  border: none;
  border-radius: 0 10px 10px 0;
  height: 50px;
}

.footer .copyright {
  opacity: 0.8;
	text-align: center;
	width: 100%;
	margin: 20px 0;
}

@media only screen and (max-width: 768px) {
	.footer .contact{
		margin-top: 20px;
	}

  .footer .contact,
  .footer .respont-logo {
    text-align: center;
  }
}
