.chat-list {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  margin-bottom: 20px;
}

.chat-list .card .list{
	transition: 0.7s;
	padding: 10px;
	display: flex;
	flex-wrap: nowrap;
	background: rgba(0,0,0,0.1);
}

.chat-list .card .list img {
  height: 58px;
  width: auto;
  border-radius: 50%;
}

.chat-list .card .list .content{
	padding: 5px 5px 5px 15px;
	width: 100%;
}

.chat-list .card .list .content p{
	font-size: 17px;
	font-weight: bold;
}

.chat-list .card .list .content p span{
	font-size: 12px;
	font-weight: normal;
}
