@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap");

@font-face {
  font-family: PoppinsBold;
  src: url(../fonts/Poppins-ExtraBold.ttf);
}

@font-face {
  font-family: PoppinsSemiBold;
  src: url(../fonts/Poppins-SemiBold.ttf);
}

@font-face {
  font-family: PoppinsLight;
  src: url(../fonts/Poppins-Light.ttf);
}

input,
input:focus,
input:hover,
button,
button:hover,
button:active,
button:focus,
textarea:focus,
textarea:hover,
textarea {
  box-shadow: none !important;
  outline: none !important;
  resize: none !important;
}

a,
a:hover {
  text-decoration: none;
  color: #17181f;
}

body {
  background: #fff;
  color: #000;
  font-family: "Poppins", sans-serif;
  background-image: url("../bg-intro.svg");
  background-repeat: no-repeat;
  background-size: 152%;
}

.text-warning {
  color: #d9b959;
}

.cursor-no-drop {
  cursor: no-drop;
}

.cursor-pointer {
  cursor: pointer;
}

.respont {
  width: 180px;
  height: auto;
}

.font-PoppinsBold {
  font-family: PoppinsBold;
}

.font-PoppinsSemi {
  font-family: PoppinsSemiBold;
}

.font-PoppinsLight {
  font-family: PoppinsLight;
}

.btn-header,
.btn-app {
  background-color: transparent;
  border: 2px solid #3364ff;
  color: #000;
  font-weight: bold;
  padding: 10px 20px;
  margin-top: 5px;
  transition: 0.5s;
}

.btn-header:hover,
.btn-header:active,
.btn-app:hover,
.btn-app:active {
  background-color: #3364ff;
  color: #fff;
}

.box-yellow {
  background-color: #d9b959;
  min-height: 200px;
  border-radius: 15px;
  color: #17181f;
}

.section-one {
  height: 91vh;
}

.section-one .col-md-7 {
  height: 91vh;
  position: relative;
}

.section-one .intro {
  margin: 0;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.section-one .intro input {
  padding: 20px;
  padding-right: 130px;
  font-size: 15px;
  border-radius: 10px;
  background-color: #fff;
  border: none;
  width: 400px;
  margin-top: 20px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1) !important;
}

.section-one .intro button {
  background-color: #3364ff;
  color: #fff;
  height: 45px;
  border: none;
  margin-left: -125px;
  padding: 0 20px;
}

.section-two .row .col-md-6 .container a,
.section-two .row .col-md-6 .container button {
  background-color: #3364ff;
  color: #fff;
  margin-top: 10px;
  padding: 10px 20px;
}

.section-two .row .col-md-6 .developers {
  background: rgba(217, 185, 89, 0.2) !important;
}

.section-two .row .col-md-6 .container,
.section-three .row .col-md-7 .row .col-lg-6 .container {
  background: rgba(219, 240, 254, 0.3);
  background-repeat: no-repeat;
  background-size: 152%;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 20px;
  padding: 30px;
  transition: 0.7s;
}

.section-two .row .col-md-6 .container p,
.section-three .row .col-md-7 .row .col-lg-6 .container p {
  text-align: justify;
  margin-bottom: 0;
}

.section-three .row .col-md-7 .row .col-lg-6 .container .icon-div {
  margin-bottom: 20px !important;
  width: 50px;
  height: 50px;
  margin: 0 auto;
  background: rgba(217, 185, 89, 0.2);
  padding: 13px;
  border-radius: 50%;
  text-align: center;
}

.section-three .row .col-md-7 .row .col-lg-6 .container .icon-div .icon {
  font-size: 25px;
  color: #d9b959;
}

.section-three .card-collapse {
  background: rgba(238, 238, 238, 0.5);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.25);
}

.section-three .components {
  border-radius: 30px;
  position: relative;
}

.section-three .components .contact-component {
  position: absolute;
  right: -70px;
  top: -100px;
  width: 90%;
  z-index: 1;
}

.section-three .components .developer-component {
  position: absolute;
  margin-top: -120px;
  left: -20px;
}

.section-four {
  width: 100%;
  padding: 70px 0;
  border-radius: 15px;
  background: rgba(51, 100, 255, 0.3);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

@media only screen and (max-width: 768px) {
  body {
    background-size: 200%;
  }

  .section-one {
    height: fit-content;
  }

  .section-one .col-md-7 {
    height: 80vh;
  }

  .section-three .row .col-md-7 .row .col-lg-6 .container {
    margin-top: 15px;
  }

  .section-one .intro input {
    width: 97%;
  }

  .section-three .components {
    margin-top: 100px;
  }

  .section-three .components .contact-component {
    right: -20px;
  }

  .section-three .components .developer-component {
    width: 90%;
  }

  .section-four {
    border-radius: 0;
  }
}
