.contact-list .card {
	background: rgba(238, 238, 238, 0.5);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.25);
}

.contact-list .card .list {
  display: flex;
  flex-wrap: nowrap;
}

.contact-list .card .list img {
  width: 50px;
  height: 50px;
	margin-right: 10px;
	border-radius: 50%;
}

.contact-list .card .list .content {
  width: 100%;
	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
