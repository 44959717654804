@import url("https://fonts.googleapis.com/css2?family=Open+Sans&family=Source+Code+Pro&display=swap");

.developer-card {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  margin-bottom: 20px;
}

.developer-card .header {
  padding: 0 13px;
  height: 28px;
  border-radius: 15px 15px 0 0;
  background: #3a353f;
  width: 100%;
}

.developer-card .header div {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  position: absolute;
  margin-top: 5px;
}

.developer-card .header div .bg-warning {
  margin-left: 15px;
}

.developer-card .header div .bg-success {
  margin-left: 30px;
}

.developer-card .body {
  background: #1f1f1f;
  padding: 10px;
  width: 100%;
  border-radius: 0 0 15px 15px;
  font-family: "Source Code Pro", sans-serif;
  color: #fff;
  overflow-x: auto;
}

@media only screen and (max-width: 1200px) {
  .developer-card .body p {
    width: 180%;
  }
}
